/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Change default colours */
:root {
    // primary 
    --ion-color-primary: #516bb3; //#007CB6;
    --ion-color-primary-rgb: 81, 107, 179; //0, 124, 182;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #4c63a4; //#0375AA;
    --ion-color-primary-tint: #627fce; //#048ECF;
  
    // secondary 
    --ion-color-secondary: #681141;
    --ion-color-secondary-rgb: 104, 17, 65;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #7B104B;
    --ion-color-secondary-tint: #6A1C47;
  
    // tertiary 
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;
  
    // success 
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;
  
    // warning 
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
  
    // danger 
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
  
    // dark 
    --ion-color-dark: #4A4B4B;
    --ion-color-dark-rgb: 74, 75, 75;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #2f3133;
    --ion-color-dark-tint: #494a4b;
  
    // medium 
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;
  
    // light 
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
}

/* Font Settings */
@font-face {
    font-family: 'Montserrat Bold';
    src: url('./assets/fonts/Montserrat-Bold.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Light';
    src: url('./assets/fonts/Montserrat-Light.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Regular';
    src: url('./assets/fonts/Montserrat-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

* {
    //font-family: 'Montserrat Regular';
    color: var(--ion-color-dark);
}

/* Other Global Styles */
.ios-padding {
    padding-top: 40px;
}

ion-icon {
    // To remove icon name title
    pointer-events: none;
}

.cursor {
    cursor: pointer;
}

.empty-row {
    margin-top: 20%;

    .img {
        height: 100px;
        width: auto;
    }

    .icon {
        height: 80px;
        width: 80px;
        color: var(--ion-color-medium);
    }

    .label {
        display: block;
        font-size: 15px;
        margin-top: 20px;
        color: var(--ion-color-medium);
    }

    .button {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.spinner-primary {
    color: var(--ion-color-primary);
}

.toast-class {
    text-align: center;
    color: var(--ion-color-dark);
}

.active-tab {
    color: var(--ion-color-primary);
}

.line-break {
    display: block;
}

.btn-spinner {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    color: var(--ion-color-light);
}

.form-field-note {
    font-size: 18px;
    font-weight: 100;
    color: var(--ion-color-medium);
}

div.gui-grid-title {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

// Modal classes
.modal-w100-h100 {
    --width: 100% !important;
    --height: 100%;
    align-items: flex-end;
}

.modal-w100-h90 {
    --width: 100% !important;
    --height: 90%;
    align-items: flex-end;
}

.modal-w100-h80 {
    --width: 100% !important;
    --height: 80%;
    align-items: flex-end;
}

.modal-w100-h50 {
    --width: 100% !important;
    --height: 50%;
    align-items: flex-end;
}

// New form styles (added in ionic 8)
ion-input.form-input-label .label-text{
    font-size: 13px;
    color: var(--ion-color-secondary);
    font-weight: 600;
}

ion-textarea.form-input-label .label-text{
    font-size: 13px;
    color: var(--ion-color-secondary);
    font-weight: 600;
}

// For forms (master and utilities)
.form-grid {
    ion-item {
        font-size: 14px;
        color: var(--ion-color-secondary);
    }

    ion-label {
        font-size: 14px;
        font-weight: 600;
        color: var(--ion-color-secondary);
    }

    ion-input .label-text {
        font-size: 14px;
        font-weight: 600;
        color: var(--ion-color-secondary);
    }

    ion-note {
        font-size: 14px;
    }

    ion-toggle {
        height: 55px;

        font-size: 14px;
        font-weight: 600;
        color: var(--ion-color-secondary);
    }

    ion-select {
        font-size: 14px;
        font-weight: 600;
        color: var(--ion-color-secondary);
    }

    .icon {
        width: 30px;
        height: 30px;
    }

    .icon-small {
        width: 20px;
        height: 20px;
    }

    .chip-icon-padding {
        padding-top: 7px;
        padding-left: 3px;
    }

    .validation-message {
        margin: 5px 0px 0px 20px;
        font-size: 13px;
        color: var(--ion-color-danger);
    }

    .submit-btn {
        padding: 10px 0px 0px 10px;
    }
}

// For transaction forms 
.transaction-form-grid {
    
    .select-btn {
        margin-top: 14px;
        float: right;
        font-size: 11px;
        color: var(--ion-color-primary);
    }

    .customer-name {
        margin-left: 10px;
        font-size: 16px;
        font-weight: normal;
    }

    .select-label {
        font-size: 12px;
    }

    .select-control {
        width: 100%;

        .label {
            width: 100%;
            float: left;
            font-size: 14px;
            // font-weight: bold;
            //overflow: hidden;
            //white-space: nowrap;
        }

        .icon-arrow {
            width: 12px;
            height: 12px;
            float: right;
            margin-top: 2px;
            margin-left: 2px;
        }

    }

    .form-label-stacked {
        font-size: 13px;
        color: var(--ion-color-secondary);
        font-weight: 600;
    }

    .form-label {
        font-size: 14px;
        color: var(--ion-color-secondary);
        font-weight: 600;
    }

    .optional-label {
        font-size: 14px;
    }

    .submit-button-row {
        margin-top: 20px;
    }

    .products-row {
        width: 100%;
        padding: 15px 15px 15px 0px;

        .item-row {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: solid 1px var(--ion-color-light);

            .img-thumb {
                width: 100%;
                height: auto;
            }

            .offer-float {
                position: absolute;
                top: 10px;
                left: 0px;
                font-size: 10px;
                margin-left: 5px;
                padding: 2px 5px 2px 5px;
                background-color: var(--ion-color-warning);
                color: var(--ion-color-dark);
                border-radius: 5px;
            }

            .name {
                width: 100%;
                float: left;
                font-size: 13px;
                font-weight: 600;
                color: var(--ion-color-dark);
                padding-left: 10px;
                white-space:normal;
            }

            .stock-count {
                margin-left: 5px;
                padding: 5px;
                font-size: 11px;
                border-radius: 10px;
                color: var(--ion-color-light);
                background-color: var(--ion-color-success);
            }
    
            .desc {
                width: 100%;
                float: left;
                font-size: 14px;
                color: var(--ion-color-medium);
                padding-left: 10px;
                white-space:normal;
            }

            .unit-price {
                line-height: 30px;
                margin-left: 10px;
                padding-right: 5px;
                font-size: 12px;
                font-weight: bolder;
                color: var(--ion-color-secondary);
            }

            .quantity-picker-row {
                margin: 0px 0px 0px 0px;

                .unit-factor {
                    padding-left: 10px;
                    padding-right: 10px;
                    font-size: 10px;
                    font-weight: 600;
                    color: var(--ion-color-dark);
                }

                .unit-label {
                    font-size: 11px;
                    color: var(--ion-color-dark);
                }
    
                .select-icon {
                    margin: 5px 0px 0px 5px;
                }

                .quantity-picker-col {
                    margin-top: 0px;
                    
                    .amount {
                        width: 100%;
                        text-align: center;
                        font-size: 13px;
                        color: var(--ion-color-secondary);
                    }
                }
            }

            .base-unit {
                padding-left: 10px;
                line-height: 55px;
                font-size: 12px;
                font-weight: normal;
                color: var(--ion-color-warning);
            }

            .product-price-input {
                height: 25px;
                width: 75px;
                font-size: 13px;
                color: var(--ion-color-secondary);
            }

            // ------- Added for expiry details -------
            .product-input {
                margin-left: 10px;
                width: 100%;
                height: 30px;
                font-size: 14px;
                color: var(--ion-color-secondary);
                //border: solid 1px var(--ion-color-medium);
            }

            .product-input-label {
                padding-left: 10px;
                line-height: 30px;
                font-size: 12px;
                font-weight: normal;
                color: var(--ion-color-warning);
            }

            .product-add-expiry-btn {
                margin-left: 15px;
                padding-top: 10px;
            }

            .product-exp-date {
                margin-left: 20px;
                margin-top: 12px;
            }
        }

    }

    .stock-adjustment-products-row {
        width: 100%;
        padding: 15px 15px 15px 0px;

        .item-row {
            padding: 0px 0px 10px 0px;
            margin: 0px 0px 15px 0px;
            border-bottom: solid 1px var(--ion-color-light);

            .img-thumb {
                width: 100%;
                height: auto;
            }

            .offer-float {
                position: absolute;
                top: 10px;
                left: 0px;
                font-size: 10px;
                margin-left: 5px;
                padding: 2px 5px 2px 5px;
                background-color: var(--ion-color-warning);
                color: var(--ion-color-dark);
                border-radius: 5px;
            }

            .name {
                width: 100%;
                float: left;
                font-size: 13px;
                font-weight: 600;
                color: var(--ion-color-dark);
                padding-left: 10px;
                white-space:normal;
            }

            .delete-icon {
                width: 20px;
                height: 20px;
                color: var(--ion-color-danger);
            }

            .details-row {
                margin: 0px 0px 15px 0px;

                .label {
                    font-size: 11px;
                    color: var(--ion-color-dark);
                }

                .select-icon {
                    margin: 5px 0px 0px 5px;
                }

                .show-hide-icon {
                    width: 20px;
                    height: 20px;
                    padding-top: 10px;
                    color: var(--ion-color-medium);
                }
            }

            .product-input {
                margin-left: 10px;
                width: 100%;
                height: 30px;
                font-size: 14px;
                color: var(--ion-color-secondary);
            }

            .product-exp-date {
                margin-left: 20px;
                margin-top: 12px;
            }
        }

    }
}

// For transaction list
.transaction-list-gird {
    .caption {
        font-size: 13px;
        font-weight: 600;
    }

    .date {
        font-size: 11px;
        color: var(--ion-color-medium);
    }

    .status-label {
        padding-left: 20px;
        line-height: 30px;
        font-size: 13px;
        font-weight: 600;
    }

    .row-status {
        .left {
            width: 40%;
            float: left;
        }

        .right {
            width: 20%;
            float: right;
            text-align: right;

            .up-down-icon {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }

            .print-icon {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }

            .details-icon {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
    }
}

// For accordion form
.accordion-form-outer {
    .accordion-header-label {
        font-size: 14px;
        color: var(--ion-color-warning);
    }
    
    .step-outer {
        .select-btn {
            margin-top: 14px;
            float: right;
            font-size: 11px;
            color: var(--ion-color-primary);
        }
    
        .customer-name {
            margin-left: 10px;
            font-size: 16px;
            font-weight: normal;
        }
    
        .select-label {
            font-size: 12px;
        }
    
        .select-control {
            width: 100%;
    
            .label {
                width: 100%;
                float: left;
                font-size: 13px;
            }
    
            .icon-arrow {
                width: 12px;
                height: 12px;
                float: right;
                margin-top: 2px;
                margin-left: 2px;
            }
    
        }
    
        .form-label-stacked {
            font-size: 13px;
            color: var(--ion-color-secondary);
            font-weight: 600;
        }
    
        .form-label {
            font-size: 13px;
            color: var(--ion-color-secondary);
            font-weight: 600;
        }
    
        .optional-label {
            font-size: 13px;
        }
    
        .product-selection-row {
            width: 100%;
            //padding: 15px 15px 15px 0px;
    
            .add-button {
                height: 22px;
                padding: 0px;
                margin: 0px;
                font-size: 14px;
            }
            
            .label {
                font-size: 13px;
                color: var(--ion-color-secondary);
                font-weight: 600;
            }
        }
    
        .products-row {
            width: 100%;
            padding: 0px 0px 0px 0px; 
    
            .item-row {
                margin-bottom: 5px;
                border-top: solid 1px var(--ion-color-medium);
    
                .img-thumb {
                    width: 100%;
                    height: auto;
                }
    
                .offer-float {
                    position: absolute;
                    top: 10px;
                    left: 0px;
                    font-size: 10px;
                    margin-left: 5px;
                    padding: 2px 5px 2px 5px;
                    background-color: var(--ion-color-warning);
                    color: var(--ion-color-dark);
                    border-radius: 5px;
                }
    
                .name {
                    width: 100%;
                    float: left;
                    font-size: 13px;
                    font-weight: 600;
                    color: var(--ion-color-dark);
                    padding-left: 0px;
                    white-space:normal;
                }

                .stock-count {
                    margin-left: 5px;
                    padding: 5px;
                    font-size: 11px;
                    border-radius: 10px;
                    color: var(--ion-color-light);
                    background-color: var(--ion-color-success);
                }
        
                .desc {
                    width: 100%;
                    float: left;
                    font-size: 14px;
                    color: var(--ion-color-medium);
                    padding-left: 0px;
                    white-space:normal;
                }
    
                .dn-section {
                    background-color: lightgreen;
                    border-radius: 10px;
                    margin-bottom: 10px;
                }
    
                .inv-section {
                    background-color:lightblue;
                    border-radius: 10px;
                    margin-bottom: 10px;
                }
    
                .sr-section {
                    background-color: lightpink;
                    border-radius: 10px;
                    margin-bottom: 10px;
                }
    
                .dr-section {
                    background-color: lightgray;
                    border-radius: 10px;
                    margin-bottom: 10px;
                }
    
                .section-label {
                    height: 25px;
                    width: 100%;
                    padding-left: 7px;
                    padding-top: 7px;
                    font-size: 13px;
                    font-weight: 600;
                    color: var(--ion-color-dark);
                }
    
                .unit-price {
                    line-height: 30px;
                    margin-left: 0px;
                    padding-left: 0px;
                    padding-right: 5px;
                    font-size: 13px;
                    font-weight: 600;
                    color: var(--ion-color-danger);
                }
    
                .unit-label {
                    font-size: 11px;
                    color: var(--ion-color-dark);
                }
    
                .unit-select-icon {
                    margin: 5px 0px 0px 5px;
                }
    
                .unit-factor {
                    display: block;
                    font-size: 10px;
                    font-weight: 600;
                    padding-left: 10px;
                    color: var(--ion-color-dark);
                }
    
                .product-input {
                    display: block;
                    margin-top: 7px;
                    height: 25px;
                    line-height: 30px;
                    min-height: 25px;
                    width: 100%;
                    font-size: 12px;
                    font-weight: 600;
                    color: var(--ion-color-danger);
                    border: solid 1px var(--ion-color-primary);
                    border-radius: 5px;
                    background-color: var(--ion-color-light);
                    text-align: left;
                }
    
                .base-unit {
                    display: block;
                    width: 100%;
                    line-height: 20px;
                    padding-top: 7px;
                    font-size: 10px;
                    font-weight: 600;
                    color: var(--ion-color-dark);
                }
    
                .amount {
                    width: 100%;
                    //text-align: center;
                    padding: 15px 10px 0px 0px;
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--ion-color-secondary);
                }
    
                .label-discount {
                    font-size: 12px;
                    color: var(--ion-color-danger);
                }
                
                .show-hide-icon {
                    width: 24px;
                    height: 24px;
                    color: var(--ion-color-medium);
                }
    
                .foc-btn {
                    margin: 10px 0px 0px 10px;
                }
    
                .foc-row {
                    margin: 8px;
                    padding: 1px 5px 5px 5px;
                    border: dashed 1px var(--ion-color-success);
                    border-radius: 5px;
                    background-color: var(--ion-color-light);
        
                    .label {
                        line-height: 16px;
                        font-size: 12px;
                        font-weight: normal;
                        color: var(--ion-color-dark);
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
        
                    .label-price {
                        line-height: 22px;
                        padding-right: 15px;
                        font-size: 11px;
                        font-weight: 600;
                        color: var(--ion-color-dark);
                    }
        
                    .label-amount {
                        line-height: 40px;
                        font-size: 12px;
                        font-weight: 600;
                        color: var(--ion-color-dark);
                    }
        
                    .unit-chip {
                        margin-left: -2px;
                    }
        
                    .label-small {
                        display: block;
                        width: 100%;
                        line-height: 20px;
                        font-size: 10px;
                        font-weight: 600;
                        color: var(--ion-color-dark);
                    }
        
                    .label-small-height {
                        line-height: 28px;
                    }
        
                    .input {
                        margin-top: 5px;
                        height: 20px;
                        line-height: 30px;
                        min-height: 30px;
                        width: 90%;
                        font-size: 12px;
                        font-weight: 600;
                        color: var(--ion-color-success);
                        border: solid 1px var(--ion-color-medium);
                        border-radius: 5px;
                        background-color: #FFFFFF;
                        text-align: center;
                    }
        
                    .icon {
                        padding-top: 5px;
                    }
                }
            }
    
        }

        .purchase-invoice-products-row, .purchase-return-products-row {
            width: 100%;
            padding: 15px 15px 15px 0px;
    
            .item-row {
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: solid 1px var(--ion-color-light);
    
                .img-thumb {
                    width: 100%;
                    height: auto;
                }
    
                .offer-float {
                    position: absolute;
                    top: 10px;
                    left: 0px;
                    font-size: 10px;
                    margin-left: 5px;
                    padding: 2px 5px 2px 5px;
                    background-color: var(--ion-color-warning);
                    color: var(--ion-color-dark);
                    border-radius: 5px;
                }
    
                .name {
                    width: 100%;
                    float: left;
                    font-size: 13px;
                    font-weight: 600;
                    color: var(--ion-color-dark);
                    padding-left: 10px;
                    white-space:normal;
                }

                .expiry-icon {
                    width: 25px;
                    height: 25px;
                    padding-top: 15px;
                    color: var(--ion-color-medium);
                }
    
                .stock-count {
                    margin-left: 5px;
                    padding: 5px;
                    font-size: 11px;
                    border-radius: 10px;
                    color: var(--ion-color-light);
                    background-color: var(--ion-color-success);
                }
        
                .desc {
                    width: 100%;
                    float: left;
                    font-size: 14px;
                    color: var(--ion-color-medium);
                    padding-left: 10px;
                    white-space:normal;
                }
    
                .unit-price {
                    line-height: 30px;
                    margin-left: 10px;
                    padding-right: 5px;
                    font-size: 12px;
                    font-weight: bolder;
                    color: var(--ion-color-secondary);
                }
    
                .quantity-picker-row {
                    margin: 0px 0px 0px 0px;
    
                    .unit-factor {
                        padding-left: 10px;
                        padding-right: 10px;
                        font-size: 10px;
                        font-weight: 600;
                        color: var(--ion-color-dark);
                    }
    
                    .unit-label {
                        font-size: 11px;
                        color: var(--ion-color-dark);
                    }
        
                    .select-icon {
                        margin: 5px 0px 0px 5px;
                    }
    
                    .quantity-picker-col {
                        margin-top: 0px;
                        
                        .amount {
                            width: 100%;
                            text-align: center;
                            font-size: 13px;
                            color: var(--ion-color-secondary);
                        }
                    }
                }
    
                .base-unit {
                    padding-left: 10px;
                    line-height: 55px;
                    font-size: 12px;
                    font-weight: normal;
                    color: var(--ion-color-warning);
                }
    
                .product-price-input {
                    height: 25px;
                    width: 75px;
                    font-size: 13px;
                    color: var(--ion-color-secondary);
                }
    
                // ------- Added for expiry details -------
                .product-input {
                    margin-left: 10px;
                    width: 100%;
                    height: 30px;
                    font-size: 14px;
                    color: var(--ion-color-secondary);
                    //border: solid 1px var(--ion-color-medium);
                }
    
                .product-input-label {
                    padding-left: 10px;
                    line-height: 30px;
                    font-size: 12px;
                    font-weight: normal;
                    color: var(--ion-color-warning);
                }
    
                .product-add-expiry-btn {
                    margin-left: 15px;
                    padding-top: 10px;
                }
    
                .product-exp-date {
                    margin-left: 20px;
                    margin-top: 12px;
                }
            }
    
        }
    
        .summary-value {
            font-size: 15px;
            font-weight: 600;
        }
    
        .discount-value {
            font-size: 12px;
            color: var(--ion-color-danger);
        }
    
    }
}

// For ngx-pagination
.paginator {
    .ngx-pagination {
        padding: 0;
        font-size: 13px;

        li:not(.current, .pagination-next, .pagination-previous) {
            background: rgba(235, 235, 235, 1);
        }

        a,
        .current {
            padding: 5px;
        }
    }
}

// For tables
.table-outer {
    width: 100%;
    border: solid 1px var(--ion-color-light);

    .header-row {
        height: 30px;
        background-color: var(--ion-color-dark);

        .label {
            padding: 5px;
            font-size: 13px;
            font-weight: 600;
            color: var(--ion-color-light);
        }
    }

    .data-row {
        height: 25px;
        
        .label {
            padding: 5px;
            font-size: 13px;
        }
    }
}


// For scroll inside ion-modal
ion-modal ion-content {
    --offset-top: unset !important;
    --offset-bottom: unset !important;
    overflow: auto !important;
}

/* Confetti Animation */
.confetti-outer {
    .confetti {
        width: 15px;
        height: 15px;
        background-color: #f2d74e;
        position: absolute;
        left: 50%;
        animation: confetti 4s ease-in-out -2s infinite;
        transform-origin: left top;
      }
      .confetti:nth-child(1) {
        background-color: #f2d74e; left: 10%; animation-delay: 0;
      }
      .confetti:nth-child(2) {
        background-color: #95c3de; left: 20%; animation-delay: -5s;
      }
      .confetti:nth-child(3) {
        background-color: #ff9a91; left: 30%; animation-delay: -3s;
      }
      .confetti:nth-child(4) {
        background-color: #f2d74e; left: 40%; animation-delay: -2.5s;
      }
      .confetti:nth-child(5) {
        background-color: #95c3de; left: 50%; animation-delay: -4s;
      }
      .confetti:nth-child(6) {
        background-color: #ff9a91; left: 60%; animation-delay: -6s;
      }
      .confetti:nth-child(7) {
        background-color: #f2d74e; left: 70%; animation-delay: -1.5s;
      }
      .confetti:nth-child(8) {
        background-color: #95c3de; left: 80%; animation-delay: -2s;
      }
      .confetti:nth-child(9) {
        background-color: #ff9a91; left: 90%; animation-delay: -3.5s;
      }
      .confetti:nth-child(10) {
        background-color: #f2d74e; left: 100%; animation-delay: -2.5s;
      }
    
      @keyframes confetti {
        0% { transform: rotateZ(15deg) rotateY(0deg) translate(0,0); }
        25% { transform: rotateZ(5deg) rotateY(360deg) translate(-5vw,20vh); }
        50% { transform: rotateZ(15deg) rotateY(720deg) translate(5vw,60vh); }
        75% { transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw,80vh); }
        100% { transform: rotateZ(15deg) rotateY(1440deg) translate(10vw,110vh); }
      }
}

// For home button
ion-fab {
    //margin-top: 50px;
    margin-bottom: 50px;
}

// For product list
.product-list-row {

    .product-row {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: solid 1px var(--ion-color-light);

        .img-thumb {
            width: 100%;
            height: auto;
        }

        .offer-float {
            position: absolute;
            top: 10px;
            left: 0px;
            font-size: 10px;
            margin-left: 5px;
            padding: 2px 5px 2px 5px;
            background-color: var(--ion-color-warning);
            color: var(--ion-color-dark);
            border-radius: 5px;
        }

        .name {
            width: 100%;
            float: left;
            font-size: 14px;
            font-weight: 600;
            color: var(--ion-color-dark);
            padding-left: 10px;
            white-space:normal;
        }

        .stock-count {
            margin-left: 5px;
            padding: 5px;
            font-size: 11px;
            border-radius: 10px;
            color: var(--ion-color-light);
            background-color: var(--ion-color-success);
        }

        .desc {
            width: 100%;
            float: left;
            font-size: 14px;
            color: var(--ion-color-medium);
            padding-left: 10px;
            white-space:normal;
        }

        .unit-price {
            line-height: 30px;
            margin-left: 10px;
            padding-right: 5px;
            font-size: 12px;
            font-weight: bolder;
            color: var(--ion-color-secondary);
        }

        .unit-label {
            font-size: 11px;
            color: var(--ion-color-dark);
        }

        .unit-select-icon {
            margin: 5px 0px 0px 5px;
        }

        .unit-selected-label {
            margin-top: -6px;
            padding-left: 10px;
        }

        .product-input {
            display: block;
            margin: 0 auto;
            margin-top: 4px;
            height: 30px;
            line-height: 30px;
            min-height: 25px;
            width: 90%;
            font-size: 12px;
            font-weight: 600;
            color: var(--ion-color-danger);
            border: solid 1px var(--ion-color-primary);
            border-radius: 5px;
            background-color: var(--ion-color-light);
            text-align: left;
        }

        .label {
            width: 100%;
            padding: 10px 10px 0px 0px;
            font-size: 14px;
            font-weight: 600;
            color: var(--ion-color-secondary);
        }

        .label-small {
            display: block;
            width: 100%;
            line-height: 20px;
            padding-top: 7px;
            font-size: 10px;
            font-weight: 600;
            color: var(--ion-color-dark);
        }

        .label-discount {
            font-size: 12px;
            color: var(--ion-color-danger);
        }

        .stock-out-label {
            font-size: 11px;
            font-weight: normal;
            text-transform: uppercase;
            color: var(--ion-color-danger);
        }

        .foc-btn {
            margin: 10px 0px 0px 10px;
        }
        
        .foc-row {
            margin-top: 5px;
            padding: 1px 5px 5px 5px;
            border: dashed 1px var(--ion-color-success);
            border-radius: 5px;
            background-color: var(--ion-color-light);

            .label {
                line-height: 16px;
                font-size: 12px;
                font-weight: normal;
                color: var(--ion-color-dark);
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .label-price {
                line-height: 22px;
                padding-right: 15px;
                font-size: 11px;
                font-weight: 600;
                color: var(--ion-color-dark);
            }

            .label-amount {
                line-height: 40px;
                font-size: 12px;
                font-weight: 600;
                color: var(--ion-color-dark);
            }

            .unit-chip {
                margin-left: -2px;
            }

            .label-small {
                display: block;
                width: 100%;
                line-height: 20px;
                font-size: 10px;
                font-weight: 600;
                color: var(--ion-color-dark);
            }

            .label-small-height {
                line-height: 28px;
            }

            .input {
                margin-top: 5px;
                height: 20px;
                line-height: 30px;
                min-height: 30px;
                width: 90%;
                font-size: 12px;
                font-weight: 600;
                color: var(--ion-color-success);
                border: solid 1px var(--ion-color-medium);
                border-radius: 5px;
                background-color: #FFFFFF;
                text-align: center;
            }

            .icon {
                padding-top: 5px;
            }
        }
    }
}

// For details modal
.details-modal-close-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.details-item-row {
    padding-bottom: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-bottom: solid 1px var(--ion-color-light);

    .img-thumb {
        width: 100%;
        height: auto;
    }

    .offer-float {
        position: absolute;
        top: 10px;
        left: 0px;
        font-size: 10px;
        margin-left: 5px;
        padding: 2px 5px 2px 5px;
        background-color: var(--ion-color-warning);
        color: var(--ion-color-dark);
        border-radius: 5px;
    }

    .name {
        width: 100%;
        float: left;
        font-size: 13px;
        font-weight: 600;
        color: var(--ion-color-dark);
        white-space:normal;
    }

    .desc {
        width: 100%;
        float: left;
        font-size: 14px;
        color: var(--ion-color-medium);
        white-space:normal;
    }

    .unit-price {
        line-height: 30px;
        padding-right: 5px;
        font-size: 12px;
        font-weight: bolder;
        color: var(--ion-color-secondary);
    }

    .quantity {
        text-align: center;
        line-height: 30px;
        font-size: 13px;
        font-weight: 600;
        color: var(--ion-color-dark);
    }

    .discount-value {
        width: 100%;
        line-height: 25px;
        font-size: 13px;
        color: var(--ion-color-dark);
    }

    .discount-label {
        width: 100%;
        font-size: 11px;
        font-weight: normal;
        color: var(--ion-color-medium);
    }

    .item-total {
        text-align: right;
        font-size: 13px;
        font-weight: 600;
        color: var(--ion-color-dark);
    }

    .value1 {
        font-size: 12px;
        font-weight: 600;
        color: var(--ion-color-dark);
    }

    .value2 {
        font-size: 13px;
        font-weight: 600;
        color: var(--ion-color-secondary);
    }

    .label-small {
        font-size: 11px;
        font-weight: normal;
        color: var(--ion-color-medium);
    }

    .unit-factor {
        padding: 0px 10px 0px 7px;
        font-size: 10px;
        font-weight: 600;
        color: var(--ion-color-dark);
    }

    .unit-label {
        font-size: 11px;
        color: var(--ion-color-dark);
    }

    .foc-row {
        margin-top: 5px;
        padding: 1px 5px 0px 5px;
        border: dashed 1px var(--ion-color-success);
        border-radius: 5px;
        background-color: var(--ion-color-light);

        .label1 {
            line-height: 16px;
            font-size: 12px;
            font-weight: 600;
            color: var(--ion-color-dark);
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .label2 {
            line-height: 16px;
            padding-right: 15px;
            font-size: 11px;
            font-weight: 600;
            color: var(--ion-color-dark);
        }

        .label-small {
            font-size: 11px;
            font-weight: normal;
            color: var(--ion-color-medium);
        }
    }
}

.details-collection-row {
    margin: 0px 0px 0px 15px;
    padding: 8px 0px 3px 0px;
    border-bottom: solid 1px var(--ion-color-light);

    .label {
        font-size: 13px;
    }

    .label-total {
        font-size: 13px;
        font-weight: 600;
    }
}

// For reports
.report-grid-outer {

    .filter-row {
        .form-label-stacked {
            font-size: 13px;
            color: var(--ion-color-secondary);
            font-weight: 600;
        }

        .lookup-outer {
            background-color: #FFFFFF;

            .label {
                font-size: 13px;
                color: var(--ion-color-secondary);
                font-weight: 600;
            }

            .value {
                font-size: 13px;
                color: var(--ion-color-secondary);
            }
    
            .icon {
                padding: 0px 0px 0px 5px;
                margin: 0px;
                width: 13px;
                height: 13px;
            }
        }

        .show-hide-outer {
            background-color: #FFFFFF;

            .icon {
                width: 24px;
                height: 24px;
                color: var(--ion-color-medium);
            }
        }
    }

    .gui-grid-outer {
        overflow-x: auto;
        margin-top: 15px;
    }
    
    .first-grid {
        margin-top: 0px;
    }

}

// Scrollbar styles
::-webkit-scrollbar-thumb { // For the scrollbar
    background: var(--ion-color-medium); /* Color of the scrollbar */
    border-radius: 10px; /* Rounded corners */
}
::-webkit-scrollbar { // For the scrollbar track (the background)
    width: 10px; /* Change the width of the scrollbar */
    height: 5px; /* Change the height of the scrollbar */
}